<template>
  <div v-if="notFound" class="logContainer">
    <p class="notFound">存在しないユーザーです</p>
  </div>
  <div v-else-if="data" class="logContainer">
    <h2><user-name :user="data.user" />さん</h2>
    <p class="notFound" v-if="!data.list.length">イラストがまだありません</p>
    <ul class="illustList">
      <li class="illustList-item" v-for="v in data.list" :key="v.illust_id">
        <div class="illustItem">
          <a class="illustItem-img" :href="v.path" target="_blank"><img :src="v.path" :alt="`絵しりとり No.${v.game_id}`"></a>
          <div class="illustItem-title">「{{ v.title }}」</div>
          <div class="illustItem-info">
            <router-link class="illustItem-info-game" :to="{ name: 'LogDetail', params: { gameId: v.game_id } }">ゲームNo.{{ v.game_id }}</router-link>
            - <small class="illustItem-info-date">{{ dayjs(v.created).format('YYYY/MM/DD') }}</small>
          </div>
        </div>
      </li>
    </ul>
    <pager :page="page" :max="data.max" :to="{ name: 'User', params: { userId } }" />
  </div>
  <div v-else class="logContainer">
    <loading />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import router from '@/router/index'
import { computed, ref, watch, inject } from 'vue'
import UserName from '@/components/UserName'
import Pager from '@/components/Pager'
import Loading from '@/components/Loading'
export default {
  components: { UserName, Pager, Loading },
  setup () {
    const axios = inject('axios')
    const page = computed(() => Number(router.currentRoute.value.params.page) || 1)
    const userId = computed(() => Number(router.currentRoute.value.params.userId))
    const data = ref(null)
    const notFound = ref(false)
    const fetch = () => {
      notFound.value = false
      data.value = null
      axios.get(`/api/user.php?page=${page.value}&user_id=${userId.value}`).then(res => {
        data.value = res.data
      }).catch(() => {
        notFound.value = true
      })
    }
    fetch()
    watch(() => page.value, value => {
      window.scrollTo(0, 0)
      if (value) fetch()
    })
    return {
      dayjs,
      fetch,
      data,
      notFound,
      page,
      userId
    }
  }
}
</script>

<style lang="scss" scoped>
.logContainer {
  margin: auto;
  max-width: 1200px;
  padding: 10px;
  background: var(--bg-color);
  border-radius: var(--radius);
}
.illustList {
  &-item {
    width: 23%;
    margin: 1%;
    display: inline-block;
    vertical-align: top;
  }
}
.illustItem {
  position: relative;
  display: block;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: var(--radius);
  text-shadow: 1px 1px 0 var(--bg-color), -1px 1px 0 var(--bg-color), 1px -1px 0 var(--bg-color), -1px -1px 0 var(--bg-color), 0px 1px 0 var(--bg-color), 0px -1px 0 var(--bg-color), 1px 0px 0 var(--bg-color), -1px 0px 0 var(--bg-color);
  text-decoration: none;
  color: var(--tx-color);
  &-title {
    margin-top: 4px;
    font-weight: bold;
    text-indent: -0.45em;
  }
  &-img {
    display: block;
    background: white;
    line-height: 0;
  }
  &-info {
    &-game {
      font-size: 12px;
      font-weight: bold;
    }
    &-date {
      font-size: 11px;
    }
  }
}
.notFound {
  padding: 30px 10px;
  text-align: center;
}
@media screen and (max-width: 773px) {
  .illustList-item {
    margin: 1%;
    width: 31.33%;
  }
  .illustItem-title {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .illustList-item {
    margin: 1%;
    width: 48%;
  }
}
</style>
