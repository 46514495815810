<template>
  <modal @close="$emit('close')">
    <template v-if="sent">
      <p>違反報告を送信しました</p>
      <button class="cancel" @click.prevent="$emit('close')">閉じる</button>
    </template>
    <template v-else>
      <div class="modalTitle" >{{ user.name }}さんを通報します</div>
      <form @submit.prevent="violationReport">
        <textarea cols="30" rows="8" placeholder="違反内容を入力してください" v-model="message"></textarea>
        <div class="alert">※ 虚偽の違反報告には厳格な措置が講じられます</div>
        <loading v-if="reporting" class="loading" />
        <template v-else>
          <button :class="{ disabled: !message }">違反報告を送信</button>
          <button class="cancel" @click.prevent="$emit('close')">キャンセル</button>
        </template>
      </form>
    </template>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
import { ref, inject } from 'vue'
export default {
  components: { Modal, Loading },
  emits: ['close'],
  props: {
    user: { type: Object, default: null, required: true }
  },
  setup (props) {
    const axios = inject('axios')
    const message = ref('')
    const reporting = ref(false)
    const sent = ref(false)
    const violationReport = () => {
      if (reporting.value) return
      if (!message.value) return
      reporting.value = true
      const params = new URLSearchParams()
      params.append('message', message.value)
      params.append('user_id', props.user.user_id)
      return axios.post('/api/violation_report.php', params).then(() => {
        sent.value = true
      }).catch(() => {
        alert('違反報告に失敗しました')
      }).finally(() => {
        reporting.value = false
      })
    }
    return {
      message,
      reporting,
      sent,
      violationReport
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  margin-top: 10px;
  font-size: 12px;
  color: #C00;
}
form,
button {
  margin-top: 10px;
}
</style>
