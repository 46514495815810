<template>
  <div class="userInfoCover" :style="{ backgroundImage: user.icon ? `url(${user.icon})` : undefined }" />
  <div class="userInfoContent">
    <div class="userInfoContent-icon" v-if="user.icon">
      <img :src="user.icon" alt="">
    </div>
    <div class="userInfoContent-desc">
      <div class="userInfoContent-desc-name">
        {{ user.name }}
        <i class="material-icons" v-if="user.verified">verified</i>
        <i class="material-icons" v-else-if="user.semi_verified">check_circle_outline</i>
        <small>さん</small>
      </div>
      <div class="userInfoContent-desc-detail">
        ユーザーID: {{ user.user_id }}<br>
        タイプ:
        <template v-if="user.verified">登録済みユーザー</template>
        <template v-else-if="user.semi_verified">承認済みゲストユーザー</template>
        <template v-else>ゲストユーザー</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['user']
}
</script>

<style lang="scss" scoped>
.userInfoCover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: rgba(255,255,255,0.5);
  background-size: cover;
  background-position: center;
  border-radius: var(--radius) var(--radius) 0 0;
  box-shadow: inset 0 -10px 15px rgba(0, 0, 0, 0.2);
}
.userInfoContent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 25px 0 20px;
  &-icon {
    margin-right: 15px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100px;
    border: 3px solid var(--bg-color);
    background: var(--bg-color);
    img {
      display: block;
      object-fit: cover;
      max-width: none;
      width: 100%;
      height: 100%;
    }
  }
  &-desc {
    flex: 1;
    padding-top: 26px;
    font-size: 10px;
    text-shadow: 1px 1px 0 var(--bg-color), -1px 1px 0 var(--bg-color), 1px -1px 0 var(--bg-color), -1px -1px 0 var(--bg-color), 0px 1px 0 var(--bg-color), 0px -1px 0 var(--bg-color), 1px 0px 0 var(--bg-color), -1px 0px 0 var(--bg-color);
    &-name {
      font-weight: bold;
      font-size: 17px;
    }
  }
}
small {
  font-size: 11px;
  font-weight: normal;
}
.material-icons {
  margin: 0 3px 0 0;
  font-size: 15px;
}
</style>
