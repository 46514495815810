<template>
  <span @click.stop class="userNameWrapper">
    <span class="userName" @click="modalName = user.user_id ? 'user' : null">
      <div class="userIcon" v-if="user.icon">
        <img :src="user.icon">
      </div>
      {{ user.name || '匿名' }}
      <i class="material-icons" v-if="user.verified">verified</i>
      <i class="material-icons" v-else-if="user.semi_verified">check_circle_outline</i>
    </span>
    <transition name="modal">
      <modal v-if="modalName === 'user'" @close="modalName = null" @click.stop>
        <user-info :user="user" />
        <router-link class="button" :to="{ name: 'User', params: { userId: user.user_id } }">イラスト一覧</router-link>
        <button class="cancel" @click.prevent="modalName = null">閉じる</button>
        <div class="violationReport" v-if="reportable">
          <span class="violationReport-text" @click.stop="modalName = 'report'">
            <i class="material-icons">outlined_flag</i><span>違反報告</span>
          </span>
        </div>
      </modal>
      <report-modal :user="user" v-else-if="modalName === 'report'" @close="modalName = null" />
    </transition>
  </span>
</template>

<script>
import Modal from '@/components/Modal'
import ReportModal from '@/components/ReportModal'
import UserInfo from '@/components/UserInfo'
import { inject, ref } from 'vue'
export default {
  props: ['user', 'reportable'],
  components: { Modal, ReportModal, UserInfo },
  setup (props) {
    const data = props.reportable ? inject('data') : null
    const modalName = ref(null)
    return {
      modalName,
      data
    }
  }
}
</script>

<style lang="scss" scoped>
.userNameWrapper,
.userName {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.userIcon {
  width: 1.8em;
  height: 1.8em;
  margin-right: 4px;
  background: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  border-radius: 20px;
  img {
    display: block;
    object-fit: cover;
    max-width: none;
    width: 100%;
    height: 100%;
  }
}
.material-icons {
  margin-left: 1px;
  font-size: 13px;
}
.violationReport {
  margin: 18px 0 -3px;
  text-align: center;
  &-text {
    user-select: none;
    cursor: pointer;
  }
  i {
    margin-right: 2px;
    font-size: 18px;
    vertical-align: middle;
  }
  span {
    font-size: 12px;
    vertical-align: middle;
  }
}
button,
.button {
  margin-top: 10px;
}
</style>
